<template>
  <b-sidebar
    id="add-new-project-sidebar"
    :visible="isAddNewProjectSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-add-new-project-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          添加新项目
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="addProject()"
          @reset.prevent="clearForm"
        >
          <!-- 项目名称 -->
          <validation-provider
            #default="validationContext"
            name="项目名称"
            rules="required"
          >
            <b-form-group
              label="项目名称"
              label-for="project-name"
            >
              <b-form-input
                id="project-name"
                v-model="projectData.projectName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="公司"
            rules=""
          >
            <b-form-group
              label="公司"
              label-for="company"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.company"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companyOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-show="projectData.company"
            #default="validationContext"
            name="合同"
            rules=""
          >
            <b-form-group
              label="合同"
              label-for="contract"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.contract"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contractOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="user-role">
                <template v-slot:option="option">
                  {{ option.label }}
                  <br>
                  <b-badge  v-if="option.remain >= 0" variant="light-success">
                    剩余{{ option.remain }}天
                  </b-badge>
                  <b-badge v-else variant="light-danger">
                    合同已过期
                  </b-badge>
                  <b-badge v-if="!option.signStatus" variant="light-warning" class="ml-1">
                    未经双方签署
                  </b-badge>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if = "projectData.company"
            #default="validationContext"
            name="客户商务联系人"
            rules=""
          >
            <b-form-group
              label="客户商务联系人"
              label-for="customer-bm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.customerBm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customerOptions"
                :reduce="val => val.value"
                multiple
                :clearable="true"
                input-id="user-role"
              >
              <template v-slot:option="option">
                  {{ option.label }}
                  <b-badge v-if="option.company || option.title" variant="light-primary">
                    {{ option.company + option.title}}
                  </b-badge>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
          v-if = "projectData.company"

            #default="validationContext"
            name="客户项目联系人"
            rules=""
          >
            <b-form-group
              label="客户项目联系人"
              label-for="customer-pm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.customerPm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customerOptions"
                multiple
                :reduce="val => val.value"
                :clearable="true"
                input-id="user-role"
              >
              <template v-slot:option="option">
                  {{ option.label }}
                  <b-badge v-if="option.company || option.title" variant="light-primary">
                    {{ option.company + option.title}}
                  </b-badge>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <validation-provider
            #default="validationContext"
            name="商务经理"
            rules="required"
          >
            <b-form-group
              label="商务经理"
              label-for="bm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.bm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                :reduce="val => val.value"
                multiple
                :clearable="true"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="项目经理"
            rules="required"
          >
            <b-form-group
              label="项目经理"
              label-for="bm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.pm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                :reduce="val => val.value"
                multiple
                :clearable="true"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <div class="d-flex mt-2">
            <b-button
              :disabled="!addProjectDone"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >保存
            </b-button>


            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BSpinner,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BBadge,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProjectSidebarActive',
    event: 'update:is-add-new-project-sidebar-active',
  },
  props: {
    isAddNewProjectSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      contractOptions:[],
      companyOptions:[],
      customerOptions:[],
      wechatGroupOptions:[],
      userOptions:[],
      projectData:{},
      required,
      alphaNum,
      email,
      addProjectDone: true,
    }
  },

  methods:{
    clearForm: function(){
      this.projectData = {}
      this.$refs.refFormObserver.reset()
    },
    computeRemainDays: function(dueDate){
      let due = new Date(dueDate)
      let now = new Date()
      let remain = due - now
      let days = Math.floor(remain / (24 * 3600 * 1000))
      return days
    },
    getCompanies: function(){
      this.companyOptions = []
      this.$axios.get("crm/companies/system/get_companies").then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.companies.forEach((company)=>{
            this.companyOptions.push({label:company.name,value:company.company_id})
          })
        }
      })
    },
    getSysContacts: function(){
      this.customerOptions = []
      var query = "?company_id=" + this.projectData.company
      this.$axios.get("crm/contacts/system/get_contacts" + query).then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.contacts.forEach((contact)=>{
            this.customerOptions.push({label:contact.last_name + contact.first_name,value:contact.contact_id,company:contact.company_name,title:contact.title})
          })
        }
      })
    },
    getUsers(){
      this.userOptions = []
      this.$axios.get("/manage/users/get_users").then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.users.forEach((user)=>{
            this.userOptions.push({label:user.user_name,value:user.user_id})
          })
        }
      })
    },
    addProject(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
          this.addProjectDone = false
          this.$axios.post("/collab/pm/add_project",this.projectData).then((res)=>{
           if (res.data.status == "ok"){
            this.clearForm()
            this.projectData = {}
            this.addProjectDone = true
            this.$emit("get-projects")
            this.$emit('update:is-add-new-project-sidebar-active', false)
           }
          })
        }
      })
    },
    getContracts: function(){
      this.contractOptions = []
      this.$axios.get("/crm/companies/system/get_contracts/" + this.projectData.company).then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.contracts.forEach((contract)=>{
            this.contractOptions.push({label:contract.contract_name,value:contract.contract_id,remain:this.computeRemainDays(contract.contract_due_date)})
          })
        }
      })
    },
  },
  watch:{
    "projectData.company":function(){
      this.contractOptions = []
      this.projectData.contract = null
      this.projectData.customerPm = null
      this.projectData.customerBm = null
      if(this.projectData.company != null){
        this.getContracts()
        this.getSysContacts()
      }
    },
    "isAddNewProjectSidebarActive":function(val){
      if(val){
       this.projectData = {}
       this.getCompanies()
        //this.getSysContacts()
        this.getUsers()
      }
    }
  },
  setup() {
    const {refFormObserver, getValidationState} = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
