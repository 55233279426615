<template>
  <section>
        <b-tabs pills>
                <b-tab title="看板视图" active>
                    <kanban-view 
                        :projects.sync = "projects"
                        :raw-projects = "rawProjects"
                        @get-projects="getProjects"/>
                </b-tab>

                <b-tab title="表格视图">
                    <table-view  
                        :projects = "projectsTable"
                        :users = "users"
                        @get-projects="getProjects"/>
                </b-tab>
        </b-tabs>  
    </section>
</template>

<script>
import {
  BTab,BTabs,BFormInput,BRow,BCol,BButton,BContainer
} from 'bootstrap-vue'

import KanbanView from './KanbanView'
import TableView from './TableView'
export default {
    components: {
        KanbanView,
        TableView,
        BTabs,
        BTab,
        BRow,BCol,BButton,BFormInput,BContainer
    },
    data () {
        return {
          rawProjects: {},
          projects: {},

          projectsTable: [],
          users: [],
          isAddNewProjectSidebarActive: false,
          searchQuery: '',

        }
    },
    methods:{
        getProjects(){
            this.$axios.get("/collab/pm/get_projects").then(resp =>{
                if (resp.data.status == "ok"){
                    this.rawProjects = resp.data.data.projects
                    this.projects = JSON.parse(JSON.stringify(this.rawProjects))

                    let projectsTable = []
                    Object.keys(this.projects).forEach(key => {
                        this.projects[key].forEach(kanban => {
                          projectsTable.push(kanban)
                        })
                    })            
                    this.projectsTable = projectsTable    
                  }
            })
        },
        getUsers(){
          this.$axios.get("/manage/users/get_users").then(resp =>{
            if (resp.data.status == "ok"){
              this.users = resp.data.data.users
            }
          })
        },
    },
    created(){
        this.getProjects()
        this.getUsers()
    }
}
</script>