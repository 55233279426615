<template>
<b-container>
      <project-add-new
        @get-projects="getProjects"
        :is-add-new-project-sidebar-active.sync="isAddNewProjectSidebarActive"
      /> 

      <b-row class="flex-nowrap" style="overflow:scroll"
>
        <b-col class="kanban" >
          <b-row>
            <h6 class="text-primary font-weight-bold justify-content-center text-nowrap ">
              <b-button  variant="primary" size="sm" @click="isAddNewProjectSidebarActive = true">
                <span>添加</span>
              </b-button>


              评估中
            </h6>
<!--             <b-col cols="12" md="auto">
                <b-button  variant="primary" size="sm" class="" @click="isAddNewProjectSidebarActive = true">
                <span>添加</span>
              </b-button>
            </b-col> -->

<!--             <b-col>
              <b-form-input
                style="max-height:90%"
                v-model="searchQuery"
                placeholder="搜索项目名称|ID|公司名称"
              />
            </b-col> -->
        </b-row>
  
          <draggable
            id="kanban1"
            ref="kanban1"
            :list="projects.kanban0"
            tag="ul"
            group="project"
            class="list-group list-group-flush cursor-move"
            :options="{handle: '.handle'}"
          >
            <b-list-group-item
              v-for="project in filteredProjects.kanban0"
              :key="project.project_id"
              :to="{ name: 'collab-pm-view', params: { id: project.project_id } }"
              tag="li"
            >
              <div>
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold d-flex justify-content-between">
                     {{ project.project_name }}
                     <feather-icon
                      icon="MenuIcon"
                      size="16"
                      class="align-middle text-body handle"
                    />
                  </b-card-text>
                  <template v-if="project.company_name">
                  <small  class="mr-1 mb-1">{{ project.company_name }}</small>
                    <br>
                    <b-badge variant="light-success" v-if="project.contract_status == 0">
                     合同有效
                    </b-badge>

                    <b-badge variant="light-danger" v-else-if="project.contract_status == 1">
                      合同已过期
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 2">
                      未绑定合同
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 3">
                      合同未经双方签署
                    </b-badge>

                    <b-badge variant="light-success" v-if="project.sow_sign_status">
                     已签订任务书
                    </b-badge>

                    <b-badge variant="light-danger" v-else>
                      未签订任务书
                    </b-badge>

                </template>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>
  
        <b-col class="kanban"
        >
          <h6 class="text-primary font-weight-bold mb-2 d-flex justify-content-center">
            商务沟通
          </h6>
          <draggable
            id="kanban2"
            ref="kanban2"
            :list="projects.kanban1"
            tag="ul"
            group="project"
            class="list-group list-group-flush cursor-move"
            :options="{handle: '.handle'}"
          >
            <b-list-group-item
            :to="{ name: 'collab-pm-view', params: { id: project.project_id } }"
              v-for="project in filteredProjects.kanban1"
              :key="project.project_id"
              tag="li"
            >
            <div>
              <div class="ml-25">
                <b-card-text class="mb-0 font-weight-bold d-flex justify-content-between">
                  {{ project.project_name }}
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                    class="align-middle text-body handle"
                  />
                </b-card-text>
                  <template v-if="project.company_name">
                  <small  class="mr-1 mb-1">{{ project.company_name }}</small>
                  <br>
                  <b-badge variant="light-success" v-if="project.contract_status == 0">
                     合同有效
                    </b-badge>

                    <b-badge variant="light-danger" v-else-if="project.contract_status == 1">
                      合同已过期
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 2">
                      未绑定合同
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 3">
                      合同未经双方签署
                    </b-badge>

                    <b-badge variant="light-success" v-if="project.sow_sign_status">
                     已签订任务书
                    </b-badge>

                    <b-badge variant="light-danger" v-else>
                      未签订任务书
                    </b-badge>
                </template>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>

        <b-col class="kanban" 
        >
          <h6 class="text-primary font-weight-bold mb-2 d-flex justify-content-center">
            执行中
          </h6>
          <draggable
            id="kanban3"
            ref="kanban3"
            :list="projects.kanban2"
            tag="ul"
            group="project"
            class="list-group list-group-flush cursor-move"
            :options="{handle: '.handle'}"
          >
            <b-list-group-item
            :to="{ name: 'collab-pm-view', params: { id: project.project_id } }"
              v-for="(project) in filteredProjects.kanban2"
              :key="project.project_id"
              tag="li"
            >
            <div>
              <div class="ml-25">
                <b-card-text class="mb-0 font-weight-bold d-flex justify-content-between">
                  {{ project.project_name }}
                  <feather-icon
                    icon="MenuIcon"
                    size="16"
                    class="align-middle text-body handle"
                  />
                </b-card-text>
                  <template v-if="project.company_name">
                  <small  class="mr-1 mb-1">{{ project.company_name }}</small>
                  <br>
                  <b-badge variant="light-success" v-if="project.contract_status == 0">
                     合同有效
                    </b-badge>

                    <b-badge variant="light-danger" v-else-if="project.contract_status == 1">
                      合同已过期
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 2">
                      未绑定合同
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 3">
                      合同未经双方签署
                    </b-badge>

                    <b-badge variant="light-success" v-if="project.sow_sign_status">
                     已签订任务书
                    </b-badge>

                    <b-badge variant="light-danger" v-else>
                      未签订任务书
                    </b-badge>
                </template>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>

        <b-col class="kanban" 
        >
          <h6 class="text-primary font-weight-bold mb-2 d-flex justify-content-center">
            已结项
          </h6>
          <draggable
            id="kanban4"
            ref="kanban4"
            :list="projects.kanban3"
            tag="ul"
            group="project"
            class="list-group list-group-flush cursor-move"
            :options="{handle: '.handle'}"
          >
            <b-list-group-item
              :to="{ name: 'collab-pm-view', params: { id: project.project_id } }"
              v-for="(project) in filteredProjects.kanban3"
              :key="project.project_id"
              tag="li"
            >
              <div>
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold d-flex justify-content-between">
                    {{ project.project_name }}
                    <feather-icon
                      icon="MenuIcon"
                      size="16"
                      class="align-middle text-body handle"
                    />
                  </b-card-text>
                  <template v-if="project.company_name">
                  <small  class="mr-1 mb-1">{{ project.company_name }}</small>
                  <br>
                  <b-badge variant="light-success" v-if="project.contract_status == 0">
                     合同有效
                    </b-badge>

                    <b-badge variant="light-danger" v-else-if="project.contract_status == 1">
                      合同已过期
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 2">
                      未绑定合同
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 3">
                      合同未经双方签署
                    </b-badge>

                    <b-badge variant="light-success" v-if="project.sow_sign_status">
                     已签订任务书
                    </b-badge>

                    <b-badge variant="light-danger" v-else>
                      未签订任务书
                    </b-badge>
                </template>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>

        <b-col class="kanban" 

        >
          <h6 class="text-primary font-weight-bold mb-2 d-flex justify-content-center">
            取消
          </h6>
          <draggable
            id="kanban5"
            ref="kanban5"
            :list="projects.kanban4"
            tag="ul"
            group="project"
            class="list-group list-group-flush cursor-move"
            :options="{handle: '.handle'}"
          >
            <b-list-group-item
              :to="{ name: 'collab-pm-view', params: { id: project.project_id } }"
              v-for="(project) in filteredProjects.kanban4"
              :key="project.project_id"
              tag="li"
            >
            <div class="ml-25">
              <b-card-text class="mb-0 font-weight-bold d-flex justify-content-between">
                {{ project.project_name }}
                <feather-icon
                  icon="MenuIcon"
                  size="16"
                  class="align-middle text-body handle"
                />
              </b-card-text>
                  <template v-if="project.company_name">
                  <small  class="mr-1 mb-1">{{ project.company_name }}</small>
                  <br>
                  <b-badge variant="light-success" v-if="project.contract_status == 0">
                     合同有效
                    </b-badge>

                    <b-badge variant="light-danger" v-else-if="project.contract_status == 1">
                      合同已过期
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 2">
                      未绑定合同
                    </b-badge>

                    <b-badge variant="light-warning" v-else-if="project.contract_status == 3">
                      合同未经双方签署
                    </b-badge>

                    <b-badge variant="light-success" v-if="project.sow_sign_status">
                     已签订任务书
                    </b-badge>

                    <b-badge variant="light-danger" v-else>
                      未签订任务书
                    </b-badge>
                </template>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>
      </b-row>
</b-container>
  </template>
  
  <script>
  import {
    BListGroupItem, BAvatar, BRow, BCol,BContainer,BButton,BCardText,BBadge, BFormInput
  } from 'bootstrap-vue'

  import draggable from 'vuedraggable'
  import Prism from 'vue-prism-component'
  import 'prismjs'
  import 'prismjs/themes/prism-tomorrow.css'
  import ProjectAddNew from './ProjectAddNew.vue'
  export default {
    components: {
      BContainer,
      BAvatar,
      BListGroupItem,
      BRow,
      BCol,
      Prism,
      draggable,
      BButton,
      ProjectAddNew,
      BCardText,
      BBadge,
      BFormInput
    },
    data() {
      return {
        searchQuery:null,
        isAddNewProjectSidebarActive: false,
      }
    },
    computed:{
      filteredProjects(){
        if (!this.searchQuery) return this.projects
        var newProjects = JSON.parse(JSON.stringify(this.projects))
        Object.keys(newProjects).forEach((kanbanList) => {
          newProjects[kanbanList] = newProjects[kanbanList].filter((project) => {
            return (project.project_name.toLowerCase().includes(this.searchQuery.toLowerCase())
            || project.company_name.toLowerCase().includes(this.searchQuery.toLowerCase())
            || project.project_id.toString().includes(this.searchQuery)
            )
          })
        })
        return newProjects
      }
    },
    props:{
      projects:{
        type:Object,
        default:()=>({})
      },
      rawProjects:{
        type:Object,
        default:()=>({})
      }
    },
    methods:{
      getProjects(){
        this.$emit('get-projects')
      },
      resortProjectsAndUpdate(){
          var newProjects = JSON.parse(JSON.stringify(this.projects))
          Object.keys(newProjects).forEach((kanbanList) => {
            newProjects[kanbanList].forEach((project, index) => {
              project.sort = index
            })
          })

          if (JSON.stringify(this.projects) == JSON.stringify(this.rawProjects)) return

           this.$axios.post("/collab/pm/update_kanban", newProjects).then(resp =>{
            if (resp.data.status == "ok"){
              this.$emit('get-projects')
            }
          }) 
      },
  },
  watch:{
    "projects":{
      handler(){
          this.resortProjectsAndUpdate()
      },
      deep:true
    }
  },

  }
  </script>
  
<style>
.b-row{
  width:200px
}
.kanban{
  min-width: 300px
}
span.badge{
  margin-right: 5px;
}
.kanban ul{
  height:100%
}
::placeholder {
    font-size: 1px;
  }
</style>