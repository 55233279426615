<template>
  <section>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>展示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条数据</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜索项目ID|项目名称"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative text-nowrap"
        :items="filterProjects"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

      <template #cell(pm)="data">
        <template v-for="pm in data.item.pm">{{ mapUserName(pm) }}
          <template v-if="data.item.pm.length > 1">；</template>
        </template>
      </template>

        <template #cell(bm)="data">
          <template v-for="bm in data.item.bm">{{ mapUserName(bm) }}
            <template v-if="data.item.bm.length > 1">；</template>
          </template>
        </template>

        <template #cell(company)="data">
          {{ mapCompanyName(data.item.company) }}
        </template>

        <template #cell(period)="data">
          {{ mapPeriod(data.item.kanban) }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'collab-pm-view', params: { id: data.item.project_id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">查看详情</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">正在展示第 {{ dataMeta.from }} 到第 {{ dataMeta.to }} 条数据 （共 {{ totalProjects }} 条数据）</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalProjects"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </section>
</template>
  
  <script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

  export default {
    components: {
      BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,vSelect
    },
    data() {
      return {
        searchQuery: "",
        perPageOptions: [10, 25, 50, 100],
        perPage: 10,
        currentPage: 1,
        sortBy: 'id',
        isSortDirDesc: false,
        fields:[
          {key:"project_id", label:"项目编号"},
          {key:"project_name", label:"项目名称"},
          {key:"company_name", label:"公司"},
          {key:"period", label:"项目阶段"},
          {key:"pm",label:"项目经理"},
          {key:"bm",label:"商务经理"},
          {key:"actions", label:"操作"},
        ],
      }
    },
    props:{
      projects:{
        type:Array,
        default:()=>[]
      },
      users:{
        type:Array,
        default:()=>[]
      },
    },
    methods:{
      mapUserName(id){
        let user = this.users.find(u => u.user_id == id)
        return user ? user.user_name : ""
      },
      mapPeriod(kanban){
        if (kanban == "0") {
          return "评估中"
        } else if (kanban == "1") {
          return "商务沟通"
        } else if (kanban == "2") {
          return "执行中"
        } else if(kanban == "3" ){
          return "已结项"
        } else if(kanban == "4"){
          return "取消"
        }
      }
    },
    computed:{
      totalProjects(){
        return this.filterProjects.length
      },
      dataMeta(){
        return {
          from: (this.currentPage - 1) * this.perPage + 1,
          to: Math.min(this.currentPage * this.perPage, this.totalProjects),
        }
      },
      filterProjects(){
        return this.projects.filter( p => {
          return (
                  p.project_id.includes(this.searchQuery)
                || p.project_name.includes(this.searchQuery)    
                )   
          })        
      }
    },


  }
  </script>
